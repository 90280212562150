@import "../../../assets/colors.scss";

.label {
	i {
		margin-top: 2px;
		padding-right: 5px;

		&:hover {
			cursor: pointer;
			color: $accent;
		}
	}
}
