@import "../../../assets/colors.scss";

$background: $primary;
$tabHeight: 50px;

#tabPanel {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 50px 20px 20px;

	.topBar {
		height: $tabHeight;
		background-color: $background;

		ul {
			text-decoration: none;
			list-style: none;
			margin: 0;
			padding: 0;
			color: #333333;

			&:first-child {
				border-left: 1px solid darken($background-grey, 10%);
			}

			li {
				background: $background;
				display: inline-block;
				padding: 15px;
				height: $tabHeight;
				border-right: 1px solid darken($background, 10%);
				transition: box-shadow 0.3s ease-in-out;
				color: white;

				&:hover {
					box-shadow: inset 0px 3px 0 0 $background-grey !important;
				}
			}

			.active {
				li {
					background-color: $background-grey;
					color: $primary;
					box-shadow: inset 0px 3px 0 0 $primary !important;
				}
			}
		}
	}

	.contentContainer {
		height: calc(100% - 50px);
		width: 100%;
		background-color: $background-grey;

		.content {
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: 95% bottom;
			background-size: 800px;

			h1 {
				padding-top: 50px;
				padding-left: 50px;
			}

			.tiles {
				padding: 50px;

				.tile {
					position: relative;
					width: 200px;
					height: 200px;
					background-color: white;
					margin-right: 30px;
					margin-bottom: 30px;
					transition: all 0.3s ease-in-out;
					color: $accent;

					&:hover {
						background-color: $accent-l;
						color: $primary;
					}

					div {
						i {
							font-size: 120px;
						}
					}

					span {
						text-align: center;
						padding-bottom: 20px;
						font-size: 20px;
					}
				}
			}
		}
	}
}
