@import "../../../assets/colors.scss";

.privacy {
	margin-top: 50px;
}

.h2 {
	margin-bottom: 20px;
}

.h3 {
	margin-bottom: 10px;
}

.p {
	margin-top: 10px;
	margin-bottom: 10px;
}
