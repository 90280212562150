.modal {
	position: fixed;
	z-index: 999;
	width: 50%;
	max-height: 80%;
	max-width: 800px;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);

	.body {
		width: fit-content;
		position: relative;

		img {
			max-height: 80vh;
			max-width: 100%;
			border: 5px solid #fff;
		}

		.button {
			cursor: pointer;
			position: absolute;
			color: #222222;
			text-decoration: none;
			background: #fff;
			border-radius: 5px;
			height: 40px;
			width: 40px;
			text-align: center;

			i {
				font-size: 30px;
			}

			&.close {
				right: 0;
				top: 0;
				border-radius: 0 0 0 5px;
				line-height: 55px;
			}

			&.next,
			&.prev {
				line-height: 36px;
				top: calc(50% - 25px);
				height: 50px;
				font-size: 60px;
			}

			&.next {
				right: 0;
				border-radius: 5px 0 0 5px;
			}

			&.pref {
				left: 0;
				right: auto;
				border-radius: 0 5px 5px 0;
			}
		}
	}
}

.overlay {
	position: fixed;
	z-index: 1;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
}
