@import "../../../assets/colors.scss";

.step {
	padding: 10px;
	cursor: pointer;

	&:hover {
		.title {
			color: $primary;
		}
	}

	&.completed {
		.title {
			color: $primary;
		}

		&:hover {
			.title {
				color: $accent;
			}
		}
	}

	&.active {
		.title {
			color: $accent;
		}
	}

	&.error {

		.title {
			color: red;
		}
	}

	.title {
		color: gray;
		margin-left: 20px;
		display: inline-block;
	}
}
