@import "./colors.scss";

a {
	text-decoration: none;
	color: inherit;

	&:active,
	&:focus {
		outline: none;
	}
}

.full-width-field {
	width: 100%;

	.k-textbox {
		width: 100%;
	}
}

.hidden {
	display: none !important;
}

button {
	img {
		width: 15px;
	}
}

.hiddenTrigger {
	.k-select {
		display: none;
	}

	.k-icon.k-clear-value.k-i-close {
		right: 5px;
	}
}

.command {
	color: $accent;
	margin-top: 2px;
	font-size: 20px;
	cursor: pointer;

	&:hover {
		color: $primary;
	}
}

.green {
	background-color: #bfe7ad !important;
	color: #006100 !important;
}

.orange {
	background-color: #ffcc99 !important;
	color: #9c5700 !important;
}

.red {
	background-color: #ffc7ce !important;
	color: #9c0006 !important;
}

.white {
	background-color: white !important;
}

.toolbarButtonContainer {
	padding-right: 8px;

	.refreshButton {
		font-size: 24px;
		color: $accent;
		cursor: pointer;
	}

	&.noButtons {
		margin: 3px 0;
	}
}

.header {
	padding: 17px;
	height: 50px;
	background-color: $primary;
	color: white;
}
