@import "../../../assets/colors.scss";

.noTopBorder {
	border-top: 0 !important;
}

.k-pager-wrap.k-grid-pager.k-widget {
	border-top: 1px;
}

.k-grid .k-grouping-row td {
	color: $accent !important;
}

.totalField {
	font-size: 21px;
	font-weight: bold;
	margin-right: 20px;
}

.dossierManagementGrid {
	td.k-state-selected:not(.green):not(.orange):not(.red),
	tr.k-state-selected > td:not(.k-group-cell):not(.green):not(.orange):not(.red) {
		color: white !important;
		background-color: #4863a0 !important;

		i {
			color: white;
		}
	}

	.complete {
		color: $font-color;
		background-color: rgba(98, 181, 229, 0.25);
	}
}
