/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'PF Square Sans Pro Regular';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Regular'), url('PFSquareSansProRegular_35489.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Italic';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Italic'), url('PFSquareSansProItalic_35484.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Thin';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Thin'), url('PFSquareSansProThin_35490.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Thin Italic';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Thin Italic'), url('PFSquareSansProThinItalic_35491.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Light';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Light'), url('PFSquareSansProLight_35485.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Light Italic';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Light Italic'), url('PFSquareSansProLightItalic_35486.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Medium';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Medium'), url('PFSquareSansProMedium_35487.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Medium Italic';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Medium Italic'), url('PFSquareSansProMediumItalic_35488.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Bold';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Bold'), url('PFSquareSansProBold_35480.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Bold Italic';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Bold Italic'), url('PFSquareSansProBoldItalic_35481.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Extra Black Italic';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Extra Black Italic'), url('PFSquareSansProExtraBlackItalic_35483.woff') format('woff');
}


@font-face {
font-family: 'PF Square Sans Pro Extra Black';
font-style: normal;
font-weight: normal;
src: local('PF Square Sans Pro Extra Black'), url('PFSquareSansProExtraBlack_35482.woff') format('woff');
}