@import "../../../assets/colors.scss";

.pdf {
	border: 0;
}

.image {
	max-height: 400px;
}

.unknownType {
	a {
		color: $accent;
		text-decoration: underline;

		&:hover {
			color: $primary;
		}
	}
}
