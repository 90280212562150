@import "../../../assets/colors.scss";

.settings {
	background-color: $background-grey;
	margin-top: 50px;
	padding: 20px;
	height: auto !important;

	.settingsTitle {
		margin-bottom: 20px;
	}

	

	.settingsFooter {
		height: 300px;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 400px;
	}
}

.buttonRow {
    margin-top: 50px;
    justify-content: center;

    button {
        margin-right: 20px;
    }
}

.setting_row_0 {
    margin-top: 50px;
}
