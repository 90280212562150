@import "../../../../assets/colors.scss";

.warehouse {
	overflow-y: scroll;

	.dossierLabel {
		flex-basis: 44%;
		height: 300px;
		margin: 50px;
		padding: 20px;
		background-color: white;

		.fields {
			height: 100%;
			justify-content: center;

			div,
			h2 {
				flex-basis: 21%;
				display: flex;
				align-items: center;
			}

			h2 {
				margin-left: 40px;
			}

			div {
				margin-left: 20px;
			}
		}

		&.fillingLabel {
			background-color: transparent;
		}
	}
}
