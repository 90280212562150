@import "../../../assets/colors.scss";

$sidebarwidth: 88px;
$itemHeigth: 50px;

#sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: $sidebarwidth;
	background-color: white;
	transition: width 0.3s ease-in-out;
	z-index: 40;

	&.open {
		width: 288px;
		transition: width 0.3s ease-in-out 0.3s;
	}

	#logo {
		background-image: url("../../../assets/SELAS_Symbool.png");
		background-size: 32px;
		background-repeat: no-repeat;
		background-position: center;
		width: $sidebarwidth;
		height: $itemHeigth;
	}

	#menu {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;

		ul {
			text-decoration: none;
			list-style: none;
			margin: 0;
			padding: 0;
			color: $primary;
			display: flex;
			flex-direction: column;
			height: 100%;

			li {
				width: $sidebarwidth;
				height: $itemHeigth;
				font-size: 32px;
				text-align: center;
				padding-top: 6px;
				width: 100%;
				transition: all 0.3s ease;

				i {
					font-size: 28px;
					display: inline-block;
					text-align: center;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}

				&.filler {
					flex-grow: 1;
				}
			}
		}

		#mainMenu {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: $sidebarwidth;

			ul {
				.active {
					li {
						box-shadow: inset 4px 0 0 0 $accent !important;
						color: $accent;

						&.hover {
							background-color: $accent-l;
							color: $primary-d;
						}
					}
				}

				li {
					.highlight {
						position: absolute;
						left: 0;
						width: 5px;
						margin-top: 15px;
						height: 0px;
						box-shadow: inset 1px 0 0 0 $accent !important;
						transition: all 0.7s ease;
					}

					&.hover {
						background-color: $accent-l;
						cursor: pointer;

						.highlight {
							margin-top: -8px;
							height: $itemHeigth;
						}
					}
				}
			}
		}

		#subMenu {
			position: absolute;
			top: 0;
			bottom: 0;
			left: $sidebarwidth;
			width: 200px;

			ul {
				li {
					font-size: 17px;
					text-align: left;
					padding-left: 15px;
					padding-top: 16px;

					&.hover {
						background-color: $accent-l;
						color: $primary-d !important;
						cursor: pointer;
					}
				}

				.active {
					li {
						color: $accent;

						&.hover {
							background-color: $accent-l;
						}
					}
				}
			}
		}
	}
}
