@import "../../../assets/colors.scss";

.home {
	position: absolute;
	padding: 50px 20px 20px;
	top: 0;
	left: 0;
	right: 0;

	.body {
		height: 100%;
		width: 100%;
		padding: 20px;
		background-color: $background-grey;

		.tile {
			background-color: white;

			h2 {
				margin: auto;
				width: fit-content;
			}
		}

		.dossierPanel {
			min-height: 100%;
			height: 400px;
			padding: 20px;
			background-color: white;
			overflow-y: auto;

			.dossierTile {
				padding: 10px;

				.content {
					padding: 10px;
					border-radius: 10px;
					height: 100px;
				}
			}
		}

		.taskPanel {
			margin-top: 20px;
			height: 320px;

			.grid {
				height: 320px;
			}
		}
	}

	.title {
		margin-bottom: 20px;
	}
}
