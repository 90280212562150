@import "./colors.scss";

$detailGridHeight: 350px;
$heightTransition: height ease-in-out 0.5s;

.masterGrid {
	height: calc(100% - 70px - #{$detailGridHeight}) !important;
	transition: $heightTransition;

	&.detailClosed {
		height: calc(100% - 70px) !important;
	}
}

.detailGrid {
	height: $detailGridHeight !important;
	transition: $heightTransition;
	background-color: white;
	overflow: hidden;

	&.closed {
		height: 0px !important;
	}

	.header {
		padding: 17px;
		height: 50px;
		background-color: $primary;
		color: white;
	}
}
