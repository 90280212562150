@import "../../../../../assets/colors.scss";

.firstFieldset {
	margin-top: 10px !important;
}

.editorSwitches {
	padding-left: 15px;

	> div {
		color: #656565;

		> span {
			margin-right: 10px;
		}

		.switch {
			margin-right: 40px;
		}
	}
}

.accentTitle {
	color: $accent !important;
}

.dossierNumber {
	text-transform: uppercase;
}
