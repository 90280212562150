.k-calendar.k-week-picker .k-range-start,
.k-calendar.k-week-picker .k-range-end,
.k-calendar.k-week-picker .k-range-mid {
	background-image: linear-gradient(
		transparent 1px,
		rgba(98, 181, 229, 0.25) 1px,
		rgba(98, 181, 229, 0.25) calc(100% - 1px),
		transparent calc(100% - 1px)
	);
}
.k-ie .k-calendar.k-week-picker .k-range-start,
.k-edge .k-calendar.k-week-picker .k-range-start,
.k-ie .k-calendar.k-week-picker .k-range-end,
.k-edge .k-calendar.k-week-picker .k-range-end,
.k-ie .k-calendar.k-week-picker .k-range-mid,
.k-edge .k-calendar.k-week-picker .k-range-mid {
	background-image: none;
	background-color: rgba(98, 181, 229, 0.25);
}

.k-calendar.k-week-picker .k-range-start.k-range-end {
	background-image: none;
	background-color: transparent;
}

.k-calendar.k-week-picker .k-range-start .k-link,
.k-calendar.k-week-picker .k-range-end .k-link {
	background-color: #62b5e5;
}

.k-calendar.k-week-picker .k-range-start.k-state-active .k-link,
.k-calendar.k-week-picker .k-range-end.k-state-active .k-link {
	box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
