@import "../../../../assets/colors.scss";

$detailGridHeight: 40%;
$heightTransition: height ease-in-out 0.5s;

.header {
	padding: 17px;
	height: 50px;
	background-color: $primary;
	color: white;
}

.masterGrid {
	height: calc(100% - #{$detailGridHeight}) !important;
	width: 500px !important;
	transition: $heightTransition;
}

.weekPlanning {
	height: calc(100% - 45px);
	overflow-y: auto;

	.weekday {
		margin-top: 5px;
		height: calc(100% - 50px);
		// outline-color: gainsboro;
		// outline-style: solid;
		// outline-width: thin;
		padding: 10px;
		padding-bottom: 40px;

		.installerContainer {
			margin-top: 10px;

			.installer {
				min-height: 325px !important;
				min-width: 0 !important;
				border-radius: 5px;
				background-color: white;

				.legendInstaller {
					color: $accent;
					font-weight: bold;
				}
			}
		}
	}
}

.detailGrid {
	height: $detailGridHeight !important;
	width: 500px !important;
	transition: $heightTransition;
	background-color: white;
	overflow: scroll;

	&.closed {
		height: 0px !important;
	}
}

.cardTitle {
	font-size: 16px !important;
}

.cardSubtitle {
	font-size: 13px !important;
}

.multiline {
	font: inherit;
	white-space: pre-wrap !important;
	word-wrap: break-word !important;
}

.time {
	font: inherit;
	white-space: pre-wrap !important;
	word-wrap: break-word !important;
	font-weight: bold;
	text-align: right !important;
}

.planningNavbar {
	padding: 17px;
	//height: 100px;
	width: 100%;
}

.userName {
	font-weight: bold;
	color: $primary;
	padding: 25px;
	text-align: center;
}
