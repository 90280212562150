.helpVideoContainer {
	display: flex;
	align-items: center;
	justify-content: center;

	.helpVideo {
		height: 480px;
		width: 850px;
		margin-top: 50px;
	}
}
