.spanStyle {
	display: inline-block;
	width: 150px;
	font-weight: bold;
}

.grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: minmax(200px, auto);
	grid-gap: 10px;
	max-width: 670px;
}

@page {
	margin: 15mm;
}