.searchBox {
	position: relative;

	i.searchIcon {
		position: absolute;
		left: 5px;
		top: 6px;
		font-size: 18px;
		z-index: 1;
	}

	input {
		padding-left: 27px !important;
	}
}
