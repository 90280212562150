@import "../../assets/colors.scss";

.companyName {
	position: fixed;
	right: 45px;
	top: 10px;
	z-index: 100;
	font-size: 20px;

	&.light {
		color: white;
	}
}

.bodyContainer {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 88px;
	right: 0;
	z-index: 30;
	background-color: $background-blue;
	overflow: auto;

	> div:first-child {
		width: 100%;
		height: 100%;
	}
}
