@import "../../../assets/colors.scss";

.tabBar {
	height: 50px;
	background-color: #ddd;
	overflow: hidden;
	z-index: 10;

	ul {
		text-decoration: none;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			cursor: pointer;
			display: inline-block;
			padding: 15px;
			height: 50px;
			border-right: 1px solid #ccc;
			transition: all 0.3s ease-in-out;
			color: #555;
			background-color: #eee;

			&:hover {
				color: white;
				background-color: $primary;
			}

			&.active {
				color: #333;
				background-color: white;
			}
		}
	}
}

.titleBar {
	padding: 7px;
	background-color: #ddd;
}

.tabBarContainer {
	position: relative;
	margin-top: 10px;
}
