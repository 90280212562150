@import "../../../../../../assets/colors.scss";

.grid {
	height: 300px;
	max-height: 300px;
	margin-bottom: 20px;
}

.accentTitle {
	color: $accent;
}
