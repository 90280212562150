@import "../../../assets/colors.scss";

.galleryContainer {
	padding: 0.9375rem 0;

	.galleryGrid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		grid-gap: 8px;
		width: 100%;
		margin: 0 auto;

		.tile {
			position: relative;
			cursor: pointer;

			&:before,
			i {
				transition: 0.3s opacity ease;
				opacity: 0;
			}

			i {
				position: absolute;
				color: #ffffff;
				left: 35%;
				top: 35%;
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: rgba(#222222, 0.5);
				display: block;
			}

			&:hover {
				&:before,
				i {
					opacity: 1;
					transition: 0.3s opacity ease;
				}
			}

			.thumbnail {
				height: 100%;
				width: 100%;
				overflow: hidden;
				background-size: cover;
				background-position: center;
			}
		}
	}
}
