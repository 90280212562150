@import "../../../../../../assets/colors.scss";

.readyForCalculationIcon {
	color: red;
	font-size: 24px;
	padding-right: 2px;
}

.productSearchHeader {
	margin-right: 0 !important;
	margin-left: 0 !important;
	background-color: rgba(0, 0, 0, 0.02);

	> div {
		padding: 4px 0 4px 8px !important;
		border-right: 1px solid rgba(0, 0, 0, 0.08);
	}
}

.productLine {
	margin-right: 0 !important;
	margin-left: 0 !important;

	> div {
		padding: 4px 0 4px 8px !important;
		border-right: 1px solid inherit;

		&.active {
			background-color: green !important;
			color: white;
		}

		&.inactive {
			background-color: red !important;
			color: white;
		}
	}
}

.primaryFieldset {
	border-color: $primary !important;
}