@import "../../../assets/colors.scss";

.myProfile {
	background-color: white;
	margin-top: 50px;
	height: auto !important;

	.myProfileTitle {
		margin-top: 10px;
	}

	.myProfileOverview {
		margin-top: 15px;

		.myProfileImage {
			background-color: white;
			width: 200px;
			height: 200px;
			border-radius: 50%;
			text-align: center;
			-webkit-box-shadow: 0px 11px 34px -5px rgba(0, 0, 0, 0.1);
			-moz-box-shadow: 0px 11px 34px -5px rgba(0, 0, 0, 0.1);
			box-shadow: 0px 11px 34px -5px rgba(0, 0, 0, 0.1);
			overflow: hidden;

			i {
				font-size: 180px;
				margin-left: 12px;
			}
		}

		.myProfileName {
			font-weight: bold;
			font-size: 1.1em;
			margin-top: 10px;
			margin-bottom: 20px;
		}

		.myProfileFields {
			position: relative;

			.myProfileField {
				font-weight: 300;
				font-size: 0.9em;
				margin-bottom: 20px;

				.myProfileFieldValue {
					position: absolute;
					left: 150px;
				}
			}
		}
	}
}
